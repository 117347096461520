import React, {Component} from 'react'
import {Form, Header, Icon, Segment, Loader, Dimmer, Transition, Message, Checkbox} from 'semantic-ui-react'

class Import extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projectName: "",
            houseID: "",
            progress: {loading: false, success: false},
            exportRevitFile:true
        };
    }

    createEntry = () => {
        let self = this;
        this.setState({progress: {loading: true, success: false}});
        fetch("https://hive.form.asrr-tech.com/api/v3/hive/configuration", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                    projectName: self.state.projectName,
                    options: {houseId: self.state.houseID, houseOptions: [], identifier: "TestID"},
                    source: 'FORM Dashboard',
                    exportRevitFile: self.state.exportRevitFile,
                },
            ),
        }).then(function (response) {
            self.setState({progress: {loading: false, success: true}});
            if (!response.ok) {
                console.log('error', response);
                throw new Error(response.statusText)
            }
            return response.json()
        }).then(
            data => {
                console.log(data);
                self.setState({progress: {loading: false, success: true}});
                this.destroyMessage();
            },
        ).catch(error => console.log(error.body))
    };

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value}, (e) => {
            console.log(this.state.projectName)
        })
    };

    handleCheckBox = () => {
        this.setState({ exportRevitFile: !this.state.exportRevitFile })
    };

    destroyMessage = () => {
        setTimeout(this.emptyFields, 6000);
    };

    emptyFields = () => {
        this.setState({progress: {loading: false, success: false}, projectName: "", houseID: ""});
    };

    render() {

        const flexCenter = {
            display:"flex",
            justifyContent:"center",
            flexDirection:"row",
            width:"100%",
            marginTop:"20px"
        };

        return (
            <div style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <div style={{
                    width: "50vw",
                    height: "80vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    backgroundColor: "#eeeeee",
                    padding: "50px"
                }} placeholder>
                    <Header icon>
                        <Icon name='write square'/>
                        Create new post
                    </Header>
                    <Form {...this.state.progress} style={{width: "100%", marginTop: "50px"}}>
                        <Form.Field>
                            <label>Project Name</label>
                            <input placeholder='Project Name' onChange={event => this.handleChange(event)}
                                   name="projectName"
                                   value={this.state.projectName}/>
                        </Form.Field>
                        <Form.Field style={{margin: "50px 0"}}>
                            <label style={{width: "auto"}}>House ID</label>
                            <input placeholder='House ID' onChange={event => this.handleChange(event)} name="houseID"
                                   value={this.state.houseID}/>

                            <div style={flexCenter}>
                                <Checkbox   name="exportRevitFile"
                                          onChange={this.handleCheckBox}
                                          className="label-color-black" label='.rvt file'
                                          checked={this.state.exportRevitFile}/>
                            </div>
                        </Form.Field>

                        {this.state.houseID.length > 0 && this.state.projectName.length > 0 ?
                            (<Form.Button onClick={this.createEntry}>Submit</Form.Button>)
                            : (<Form.Button disabled>Submit</Form.Button>)
                        }
                        <Transition visible={this.state.progress.success} animation='fade' duration={500}>
                            <Message style={{height: "100px"}}
                                     success
                                     header='New entry Created.'
                                     content="Head to thedashboard page to view the new entry"
                            />
                        </Transition>
                    </Form>
                </div>
            </div>

        )
    }
}

export default Import
