import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { Button, Grid, Icon, Message, Modal, Pagination, Segment, Table } from 'semantic-ui-react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { getStats } from './GetStats'

class Dashboard extends Component {
	constructor (props) {
		super(props)
		this.state = {
			pageNumber: this.props.pageNumber,
			configurations: {},
			time: Date.now(),
			loading: true,
			currentId: '',
			configuration: [],
			information: '',
			openInformation: false,
			exception: '',
			uploadError_Message: [],
			configurationError_Message: [],
			statsArray: [],
			json: '',
			skipButtonState: 'negative',
			downloadID: '',
			downloading: [],
		}
	}

	componentDidMount () {
		this.setState({ loading: true })

		this.fetchConfig()
		this.interval = setInterval(() => {
			this.fetchConfig()
			return this.setState({ time: Date.now() })
		}, 5000)
		this.setState({ loading: false }, () => {
		})
	}

	componentWillUnmount () {
		clearInterval(this.interval)
	}

	fetchConfig () {
		let self = this
		fetch(`${process.env.REACT_APP_API_BASE_URL}/configuration/page/${(this.props.pageNumber - 1)}`).
			then(response => response.json()).
			then(function (data) {
				self.setState({ configurations: data.configurations })
				console.log(data)
			}).
			catch(error => console.log(error))
	}

	fetchDownloadList = (id) => {
		let self = this
		fetch(`${process.env.REACT_APP_API_BASE_URL}/configuration/${id}/exports`).
			then(response => response.json()).
			then(function (data) {
				self.setState({ downloadID: data })
			}).
			catch(error => console.log(error))
	}

	handleRetry = (id, exportRevitFile, asNewRequest) => {
		let self = this
		fetch(`${process.env.REACT_APP_API_BASE_URL}/configuration/${id}/retry`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ id: id, exportRevitFile: exportRevitFile, asNewRequest: asNewRequest }),
		}).then(function (response) {

			if (!response.ok) {
				console.log('error', response)
				throw new Error(response.statusText)
			} else {
				self.closeRetryModal()
				self.fetchConfig()
			}
		}).catch(error => console.log(error))
	}

	handleSkip = (id, skipMessage) => {
		let self = this
		fetch(`${process.env.REACT_APP_API_BASE_URL}/configuration/${id}/skip`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ id: id, message: skipMessage }),
		}).then(function (response) {
			console.log('skipped')
			if (!response.ok) {
				console.log('error', response)
				throw new Error(response.statusText)
			} else {
				self.closeRetryModal()
				self.fetchConfig()
			}
		}).catch(error => console.log(error))
		this.closeSkipModal()
		this.fetchConfig()
	}

	getExport = (id, fileType, buttonID) => {
		let fileID
		let fileName
		let self = this
		console.log(buttonID)

		//Get the clicked buttons unique id and add it to state.
		let downloadButtons = this.state.downloading
		this.setState({ downloading: this.state.downloading.concat(buttonID) })
		console.log(this.state.downloading)

		fetch(`${process.env.REACT_APP_API_BASE_URL}/configuration/${id}/exports`).
			then(response => response.json()).
			then(function (data) {
				let len = data.length //Cached for loop is faster
				console.log(data)
				for (let i = 0; i < len; i++) {
					if (data[i].name.includes(fileType)) {
						fileID = data[i].id
						fileName = data[i].name
					}
				}
				fetch(`${process.env.REACT_APP_API_BASE_URL}/configuration/${id}/export/${fileID}`).
					then(resp => resp.blob()).
					then(blob => {
						const url = window.URL.createObjectURL(blob)
						const a = document.createElement('a')
						a.style.display = 'none'
						a.href = url
						// the filename you want
						a.download = fileName
						document.body.appendChild(a)
						a.click()
						window.URL.revokeObjectURL(url)
						//Remove the clicked buttons unique id form state when download has completed.
						self.setState({ downloading: downloadButtons.splice(downloadButtons.indexOf(buttonID)) })
					}).
					catch(error => {
						console.log(error)
						self.setState({ downloading: downloadButtons.splice(downloadButtons.indexOf(buttonID)) })
						alert(`download of file:${buttonID} failed`)
					})
			}).
			catch(error => console.log(error))
	}

	state = { openRetryModal: false, id: '', exportRevitFile: '', asNewRequest: '', message: '' }

	showRetryModal = (id, exportRevitFile, asNewRequest, message) => () => this.setState({
		id: id,
		exportRevitFile: exportRevitFile,
		asNewRequest: asNewRequest,
		message: message,
		openRetryModal: true,
	})
	closeRetryModal = () => this.setState({ openRetryModal: false })

	showSkipModal = (id, skipMessage, message) => () => this.setState({
		id: id,
		skipMessage: skipMessage,
		message: message,
		openSkipModal: true,
	})
	closeSkipModal = () => this.setState({ openSkipModal: false })

	showRequestModal = (request) => {
		this.setState({
			json: JSON.stringify(request, null, 4),
			showRequest: true,
		})
	}
	closeRequestModal = () => this.setState({ showRequest: false })

	handlePaginationChange = (e, { activePage }) => {
		window.location.href = '/dashboard/' + activePage
		return this.setState({ activePage })
	}

	showInfo = (id) => {
		this.setState({
			currentId: id,
			informationState: 'info',
			openInformation: (!this.state.openInformation || id !== this.state.currentId) ||
				this.state.informationState !== 'info',
		})
		this.fetchOptions(id, this.printInfo, 'options')
	}

	showUploadError = (id) => {
		this.setState({
			currentId: id,
			informationState: 'uploadError',
			openInformation: (!this.state.openInformation || id !== this.state.currentId) ||
				this.state.informationState !== 'uploadError',
		})
		this.fetchOptions(id, this.printUploadError, 'upload-exception')
	}

	showConfigurationError = (id) => {
		this.setState({
			currentId: id,
			informationState: 'configurationError',
			openInformation: (!this.state.openInformation || id !== this.state.currentId) ||
				this.state.informationState !== 'configurationError',
		})
		this.fetchOptions(id, this.printConfigurationError, 'configuration-exception')
	}

	popUpInfo = (id) => {
		this.fetchOptions(id, this.showRequestModal, 'options')
	}

	fetchOptions = (id, _callback, endpoint) => {
		fetch(`${process.env.REACT_APP_API_BASE_URL}/configuration/${id}/${endpoint}`,
		).then((response) => response.json()).then((data) => {
				_callback(data)
				console.log(data)
			},
		).catch((error => console.log(error)))
	}

	printInfo = (info) => {
		this.setState({ statsArray: getStats(info) })
		console.log(this.statsArray)
	}

	printUploadError = (info) => {
		this.setState({ uploadError_Message: info.uploadException })
	}

	printConfigurationError = (info) => {
		// console.log(info)
		this.setState({ configurationError_Message: info })
	}

	render () {
		const { openRetryModal, openSkipModal, showRequest, pageNumber, configurations } = this.state

		const skipButtonProps = {
			[this.state.skipButtonState]: true,
		}

		return <div className="dashboard">
			<Segment>
				{/*<Segment {...this.props.darkMode} inverted>*/}
				<Button primary style={{ margin: '0.5em' }} onClick={() => this.fetchConfig()}> REFRESH (Last
					refreshed {new Date(this.state.time).toISOString().slice(11, -1)}) </Button>

				<Grid.Column>
					<Pagination inverted
								activePage={pageNumber}
								onPageChange={this.handlePaginationChange}
								totalPages={100}
								siblingRange={8}
					/>
				</Grid.Column>
			</Segment>

			<div>
				<Table inverted celled selectable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell singleLine>ID</Table.HeaderCell>
							<Table.HeaderCell singleLine>BNR</Table.HeaderCell>
							{/*<Table.HeaderCell singleLine>Reference ID</Table.HeaderCell>*/}
							<Table.HeaderCell>Project Name</Table.HeaderCell>
							<Table.HeaderCell>Duration</Table.HeaderCell>
							<Table.HeaderCell>Request date</Table.HeaderCell>
							<Table.HeaderCell>Request</Table.HeaderCell>
							<Table.HeaderCell>Exports</Table.HeaderCell>
							<Table.HeaderCell singleLine>Actions</Table.HeaderCell>
							<Table.HeaderCell>Configuration Status</Table.HeaderCell>
							<Table.HeaderCell>Upload Status</Table.HeaderCell>
							<Table.HeaderCell>Source</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{Object.keys(configurations).map((keyName, index) => {
							let item = configurations[keyName]
							let downloadButtons = [
								{ type: 'rvt', id: `${item.id}-rvt` },
								{ type: 'pdf', id: `${item.id}-pdf` },
								{ type: 'dwg', id: `${item.id}-dwg` },
								{ type: 'zip', id: `${item.id}-zip` },
								{ type: 'log', id: `${item.id}-log` }]

							return <React.Fragment>
								<Table.Row
									positive={!item.thrownUploadException && !item.thrownConfigurationException &&
									item.duration !== 0}
									negative={item.thrownUploadException || item.thrownConfigurationException}
									{...item.skipped ? ({ disabled: true }) : null}
									className={item.thrownConfigurationException ? 'heavy-error' : ''}
									key={item.id}>
									<Table.Cell nonBreaking>
										{/*<div onClick={() => this.showInfo(item.id)}*/}

										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												justifyContent: 'left',
											}}>
											<Icon className="stats-icon" name="info"/>
											{item.id}
										</div>

									</Table.Cell>
									<Table.Cell>
										{item.bnr}
									</Table.Cell>
									{/*<Table.Cell>*/}
									{/*{item.referenceId === null ? "N.A" : item.referenceId}*/}
									{/*</Table.Cell>*/}
									<Table.Cell>
										{item.projectName}
									</Table.Cell>
									{item.exceptionThrown ?
										<Table.Cell>
											<Icon name='attention'/>
											{item.duration}
										</Table.Cell>
										:
										<Table.Cell>
											{item.duration}
										</Table.Cell>}

									<Table.Cell>
										{item.created}
									</Table.Cell>

									<Table.Cell>
										<Button.Group>
											<Button {...item.skipped ? ({ disabled: true }) : null}
													onClick={() => this.popUpInfo(item.id)}>
												<Icon name='magnify'/>
											</Button>
											<CopyToClipboard text={item.options}
															 onCopy={() => this.setState({ copied: true })}>
												<Button {...item.skipped ? ({ disabled: true }) : null}>
													<Icon name='clipboard outline'/>
												</Button>
											</CopyToClipboard>
										</Button.Group>
									</Table.Cell>

									<Table.Cell singleLine>
										{downloadButtons.map((buttons) => {
											return <React.Fragment>
												<Button
													loading={this.state.downloading.includes(buttons.id)}
													onClick={() => this.getExport(item.id, buttons.type, buttons.id)}
													secondary
													disabled={item.exports.indexOf(buttons.type === "zip" ? "archive_zip" :`file_${buttons.type}`) ===
													-1}>{buttons.type.toUpperCase()}</Button>
											</React.Fragment>
										})}
									</Table.Cell>

									<Table.Cell singleLine className="important-buttons">
										<Button {...item.skipped ? ({ disabled: true }) : ({ negative: false })}
												key={item.id}
												className="warn"
												onClick={this.showSkipModal(item.id, 'SkippedThroughDashboard',
													'Are you sure you want to skip this export?')}>
											Skip
										</Button>
										<Button className="retry"
												onClick={this.showRetryModal(item.id, false, false,
													'Are you sure you want to retry this export without a revit file? This will update the files displayed on any dashboards linked to the database, which may result in a signed contract being updated')}>
											<Icon style={{ paddingRight: '20px' }} name='redo'/>
											Retry
										</Button>
									</Table.Cell>

									<Table.Cell>
										{item.thrownConfigurationException &&
										<div className={'centered-button'}>
											<Button className="negative"
													onClick={() => {
														this.showConfigurationError(item.id)
													}}><Icon name='info'/>
											</Button>
										</div>
										}
									</Table.Cell>

									<Table.Cell>
										{item.thrownUploadException &&
										<div className={'centered-button'}>
											<Button className="negative"
													onClick={() => {
														this.showUploadError(item.id)
													}}><Icon name='info'/>
											</Button>
										</div>
										}
									</Table.Cell>

									<Table.Cell>
										{item.source}
									</Table.Cell>
								</Table.Row>

								{/*/Uses quotation marks to set display to its original value. */}
								{/* Other values flex, block etc makes the error message small ( 1 col wide)*/}
								<Table.Row
									style={{
										display: this.state.openInformation && this.state.currentId === item.id
											? ''
											: 'none',
									}}>
									<Table.Cell colSpan="10">
										<Fade duration={1000}>
											<React.Fragment>
												{
													(() => {
														switch (this.state.informationState) {
															case 'uploadError':
																return <Message negative>
																	<li>{this.state.uploadError_Message.storageProvider}</li>
																	<li>{this.state.uploadError_Message.message}</li>
																	<li>{this.state.uploadError_Message.exportType}</li>
																</Message>
															case 'configurationError':
																return <Message negative className="heavy-error">
																	{/*<li>{this.state.configurationError_Message.exception}</li>*/}
																	<li>{this.state.configurationError_Message.message}</li>
																	<br/>
																	<li>{this.state.configurationError_Message.stackTrace}</li>
																</Message>
															case 'info':
																return <Message info>
																	<ul>
																		{this.state.statsArray === null ?
																			<li> This file contains no blocks </li> :
																			(this.state.statsArray.map(item => {
																				return <li>{item}</li>
																			}))}
																	</ul>
																</Message>
															default:
																return null
														}
													})()
												}
											</React.Fragment>
										</Fade>
									</Table.Cell>
								</Table.Row>
							</React.Fragment>
						})
						}
					</Table.Body>
				</Table>

				<Segment {...this.props.darkMode}>
					<Grid.Column style={{ paddingBottom: '2em' }}>
						<Pagination
							activePage={pageNumber}
							onPageChange={this.handlePaginationChange}
							totalPages={10}
						/>
					</Grid.Column>
				</Segment>


				<Modal size={'mini'} open={openRetryModal} onClose={this.closeRetryModal}>
					<Modal.Content>
						<p>{this.state.message}</p>
					</Modal.Content>
					<Modal.Actions>
						<Button negative onClick={
							this.closeRetryModal}>No</Button>
						<Button positive icon='checkmark' labelPosition='right' content='Yes'
								onClick={() =>
									this.handleRetry(this.state.id, this.state.exportRevitFile, this.state.asNewRequest)
								}/>
					</Modal.Actions>
				</Modal>

				<Modal size={'mini'} open={openSkipModal} onClose={this.closeSkipModal}>
					<Modal.Content>
						<p>{this.state.message}</p>
					</Modal.Content>
					<Modal.Actions>
						<Button negative onClick={
							this.closeSkipModal}>No</Button>
						<Button positive icon='checkmark' labelPosition='right' content='Yes'
								onClick={() =>
									this.handleSkip(this.state.id, this.state.skipMessage)
								}/>
					</Modal.Actions>
				</Modal>

				<Modal size={'large'} open={showRequest} onClose={this.closeRequestModal}>
					<Modal.Content>
						<pre>{this.state.json}</pre>
					</Modal.Content>
					<Modal.Actions>
						<Button negative onClick={
							this.closeRequestModal}>Close</Button>
					</Modal.Actions>
				</Modal>
			</div>
		</div>
	}
}

export default Dashboard
