import React from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import './App.css';
import Import from './Components/Import';
import Footer from './Components/Footer';
import Dashboard from './Components/Dashboard';
import Post from './Components/Post';
import { Container, Image, Menu, Icon } from 'semantic-ui-react';
import logo from './Images/form.svg';

class App extends React.Component {

	DashboardChild = ({ match }) => {
		return (
			<div>
				<Dashboard pageNumber={match.params.pageNumber}/>
			</div>
		)
	};

	render () {

		return (
			<div className="App">
				<Menu fixed='top'
					  inverted>
					<Container>
						<Menu.Item as='a' header>
							<Image size='tiny' src={logo} style={{ marginRight: '.5em' }}/>
						</Menu.Item>
						<Menu.Item as={Link} to='/import'>Import</Menu.Item>
						<Menu.Item as={Link} to='/dashboard/1'>Dashboard</Menu.Item>
						<Menu.Item as={Link} to='/new-entry'>New Entry</Menu.Item>
					</Container>
				</Menu>


				<div className="page-content">
					<Switch>
						<Route exact path="/import" component={Import}/>
						<Route exact path="/new-entry" component={Post}/>
						<Route path="/dashboard/:pageNumber" component={this.DashboardChild}/>
					</Switch>
				</div>
				<Footer/>
			</div>
		)
	}

}

export default App
