import React, { Component } from 'react'
import { Button, Checkbox, Divider, Form, Grid, Header, Icon, Image, Progress, Segment, Input } from 'semantic-ui-react'
import Files from 'react-files'
import logo from '../Images/form.svg'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'
import Fade from 'react-reveal/Fade'

class Import extends Component {
	constructor (props) {
		super(props)
		this.fileReader = new FileReader()
		this.state = {
			percent: 0,
			submitted: false,
			requestId: '',
			status: '',
			show: false,
			data: [],
			dataLoaded: false,
			renderButton: false,
			exportRevitFile: true,
			options: null,
			projectName: null,
			source: 'form dash',
			type: 'type',
			fileName: '',
		}

		this.fileReader.onload = (event) => {
			//Clear filereader when adding another file
			console.log(this.fileReader)
			this.setState({ options: null, jsonFile: null, dataLoaded: false})
			this.setState({ jsonFile: JSON.parse(event.target.result) }, () => {
				// this.importConfiguration(this.state.jsonFile)
				// this.setState({percent: 15, status: 'UPLOADING FILES'})
			})
			console.log(event);
			this.setState({ options: this.state.jsonFile }, () => {})
			// console.log(this.state.options)
			this.setState({ dataLoaded: true })
			this.setState({ show: true })
		}
	}

	handleInput = (e, data) => {
		console.log(e)
		this.setState({ [e.target.name]: data })
	}

	handleCheckBox = () => {
		this.setState({ exportRevitFile: !this.state.exportRevitFile })
	};

	submit = () => {
		let self = this
		fetch(`${process.env.REACT_APP_API_BASE_URL}/configuration`, {
			method: 'POST',
			body: JSON.stringify({
				projectName: self.state.projectName,
				options: self.state.options,
				source: 'Form Dash',
				exportRevitFile: self.state.exportRevitFile,
				projectId: '',
			})
			,
			headers: { 'Content-Type': 'application/json' },
		}).then(function (response) {
			if (!response.ok) {
				console.log('error', response)
				throw new Error(response.statusText)
			}
			return response.json()
		}).then(
			data => {
				console.log(data)
			},
		).catch(error => console.log(error.body))
	}

	importConfiguration = (projectJson) => {
		let self = this

		fetch(process.env.REACT_APP_API_BASE_URL + '/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
					projectName: projectJson.name,
					options: projectJson,
					source: 'Dashboard Import',
					exportRevitFile: self.state.exportRevitFile,
					projectId: '1234',
				},
			),
		}).then(function (response) {
			if (!response.ok) {
				console.log('error', response)
				throw new Error(response.statusText)
			}
			return response.json()
		}).then(
			data => {
				console.log(data)
				self.setState({ submitted: true, requestId: data.configurationId })

				self.interval = setInterval(() => {
					this.fetchStatus(data.configurationId)
					return this.setState({ time: Date.now() })
				}, 5000)
			},
		).catch(error => console.log(error.body))
	}

	fetchStatus = (id) => {
		let self = this
		fetch(process.env.REACT_APP_API_BASE_URL + '/' + id + '/status', {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
		}).then(function (response) {
			if (!response.ok) {
				console.log('error', response)
				throw new Error(response.statusText)
			}
			return response.json()
		}).then(
			data => {
				let status = data.status
				if (status === 'QUEUED') {
					self.setState({ percent: 25, status: status + ', ' + data.queuePosition + ' files before you.' })
				} else if (status === 'PROCESSING') {
					self.setState({ percent: 60, status })
				} else if (status === 'FINISHED') {
					self.setState({ percent: 100, status, options: data })
				}
			},
		).catch(error => console.log(error.body))
	}

	getExport = (id, fileType, extension) => {
		window.open(process.env.REACT_APP_AZURE_BLOB_URL + `/${fileType}/${id}.${extension}`, '_blank')
		window.focus()
	}

	increment = () =>
		this.setState({
			percent: this.state.percent >= 100 ? 0 : this.state.percent + 20,
		})

	getRevitFile = () => {
		window.open(`/export.zip`, '_blank')
		window.focus()
	}

	render () {
		const { fileName, dataLoaded } = this.state
		return (
			<div className="white">
				<Grid verticalAlign='middle' stackable textAlign='center'>
					<Grid.Row style={{ height: '60vh' }}>
						<Grid.Column style={{ maxWidth: '800px' }}>
							<Segment className="drop-shadow">
								<Image size="small" src={logo} style={{ margin: 'auto' }}/>
								<Divider/>
								<Files
									className="files-dropzone white"
									onChange={file => {
										this.fileReader.readAsText(file[0])
									}}
									onError={err => console.log(err)}
									accepts={['.json']}
									multiple
									maxFiles={3}
									maxFileSize={10000000}
									minFileSize={0}
									clickable
								>
									<Segment placeholder>
										<Header as='h3'> Drag and drop or select a new file</Header>
										<Header as='h4' icon>
											<Icon name='file outline'/>
											{dataLoaded ? `File added` : 'Import JSON'}
											{/*{fileName !== "" ? `${fileName} added` : "Import JSON" }*/}
										</Header>
										{dataLoaded ?
											(<Button secondary className="add-file-button">Add another file</Button>)
											:
											(<Button secondary className="add-file-button">Add file</Button>)}
									</Segment>
								</Files>
								<Divider/>

								<Form>
									<Form.Group widths='equal'>
										<Form.Field>
											<Checkbox  name="exportRevitFile"
													  onChange={this.handleCheckBox}
													  className="label-color" label='.rvt file'
													  checked={this.state.exportRevitFile}/>
										</Form.Field>
										<Form.Field>
											<Checkbox className="label-color" label='.pdf file'/>
										</Form.Field>
									</Form.Group>
								</Form>

								<div style={{ display: this.state.percent === 0 ? 'none' : '' }}>
									<Divider/>
									<Progress percent={this.state.percent} label={this.state.status} indicating/>
									<Divider/>
								</div>

								<div style={{ display: this.state.percent === 100 ? '' : 'none' }}>
									<Button
										onClick={() => this.getExport(this.state.requestId, 'rvt-collection', 'zip')}
										secondary
									>Download</Button>
								</div>

								<Message hidden={!this.state.submitted}>Configuration submitted with
									requestId <b>{this.state.requestId}</b>
								</Message>

								{/*<Message icon>*/}
								{/*<Message.Content>*/}
								{/*	<Message.Header>Configuration stats</Message.Header>*/}
								{/*	{showStats()}*/}
								{/*</Message.Content>*/}
								{/*</Message>*/}

								<div style={{
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'center',
									width: '100%',
									flexDirection: 'column',
									marginTop: '50px',
								}}>
									<Input name="projectName" style={{ width: '100%' }}
										   onChange={e => this.handleInput(e, e.target.value)}
										   label="Project Name"/>
									{this.state.projectName && this.state.options !== null ?
										(
											<Button onClick={this.submit} secondary
													style={{ marginTop: '50px', height: '50px' }}
													className="add-file-button">Create Configuration</Button>
										)
										:
										(
											<Button style={{ marginTop: '50px', height: '50px' }} disabled>Create
												Configuration</Button>
										)
									}
								</div>

							</Segment>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</div>

		)
	}
}

export default Import
